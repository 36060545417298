<template>
	<div>
		<transition-group name="slide-in" :style="{ '--total': value.length }">
			<v-row
				v-for="(data, index) in value"
				v-bind:key="'list' + index"
				no-gutters
				:style="{ '--i': index }"
				class="list-box mb-3 d-sm-flex d-block add-remove-item"
			>
				<div class="list-info d-flex">
					<div v-if="!data.bid.status" class="no-bid-point"></div>
					<div class="list-icon d-flex align-center justify-center">
						<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt="" />
						<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt="" />
						<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt="" />
						<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt="" />
						<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt="" />
						<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt="" />
					</div>
					<div class="list-detail d-flex align-center pl-8 pr-8">
						<div style="width: 100%">
							<p class="heading-mf15">{{ data.list.list_name }}</p>
							<div class="d-flex">
								<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

								<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">quotes: {{ data.list.active_bids }}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="list-address-info d-flex align-center pl-8 pr-8">
					<div class="pt-3 pb-3">
						<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
						<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
					</div>
				</div>

				<div class="status-info d-sm-flex align-center pl-8 pr-8">
					<div v-if="!data.bid.status && data.list.status === 'A'">
						<app-list-hours v-model="data.list.idle_time"></app-list-hours>
					</div>
					<div v-else>
						<p class="heading-mf15">Your quote</p>
						<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
					</div>
					<div class="ml-auto">
						<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">Accepted</div>
						<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">Lost-Quote</div>
						<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">List Expired</div>
						<div
							v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
							class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
						>
							<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
							<p class="ma-0" v-else-if="data.bid.status === 'E'">Quote Expired</p>
							<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
							<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submitted</p>
						</div>
						<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">Rejected</div>
					</div>
					<div class="my-btn-disabled">
						<v-btn
							v-if="!data.bid.status"
							class="my-p-btn my_elevation"
							id="my_elevation"
							depressed
							@click.prevent="bid(data.list.list_id)"
							:disabled="data.list.status === 'E' || data.list.status === 'BA'"
							>Quote</v-btn
						>

						<v-btn
							v-else-if="data.bid.status === 'A'"
							class="my-p-btn my_elevation"
							id="my_elevation"
							depressed
							@click.prevent="bidDelivery(data.bid.bid_id)"
							>Delivery</v-btn
						>

						<v-btn
							v-else-if="data.bid.status === 'AC'"
							class="my-p-btn my_elevation"
							id="my_elevation"
							depressed
							@click.prevent="checkBid(data.bid.bid_id)"
							:disabled="data.list.status === 'E' || data.list.status === 'BA'"
							>Check Quote</v-btn
						>

						<v-btn v-else-if="data.bid.status === 'LB'" class="my-p-btn my_elevation" id="my_elevation" depressed disabled>Lost-Quote</v-btn>

						<v-btn
							v-else-if="data.bid.status === 'R' || data.bid.status === 'E'"
							class="my-p-btn my_elevation"
							id="my_elevation"
							depressed
							@click.prevent="reBid(data.bid.bid_id)"
							:disabled="data.list.status === 'E' || data.list.status === 'BA'"
							>Re-Quote</v-btn
						>
					</div>
				</div>
			</v-row>
		</transition-group>
		<app-bid ref="bid"></app-bid>
		<app-re-bid ref="rebid"></app-re-bid>
		<app-check-bid ref="checkbid"></app-check-bid>
		<app-bid-delivery ref="deliverybid"></app-bid-delivery>
	</div>
</template>

<script>
const Bid = () => import('../views/bidcomponent/Bid')
const ReBid = () => import('../views/bidcomponent/ReBid')
const CheckBid = () => import('../views/bidcomponent/CheckBid')
const BidDelivery = () => import('../views/bidcomponent/BidDelivery')

// import { lists } from '../services/listservice'
// import { errorLogs } from '../services/authservices'

export default {
	components: {
		AppBid: Bid,
		AppReBid: ReBid,
		AppCheckBid: CheckBid,
		AppBidDelivery: BidDelivery,
	},

	props: {
		value: Array,
	},

	methods: {
		firstAddress(address) {
			if (address) {
				let first_address = address.split(',')
				return first_address[0]
			}
		},

		lastAddress(address) {
			if (address) {
				let last_address = address.split(',')
				let full_address = last_address[1] != undefined ? last_address[1] : ''
				full_address = last_address[2] != undefined ? full_address + ', ' + last_address[2] : full_address
				full_address = last_address[3] != undefined ? full_address + ', ' + last_address[3] : full_address
				return full_address
			}
		},

		getList(type, id) {
			let data = {}
			if (type === 'list') {
				data = this.value.filter((list) => {
					return list.list.list_id === id
				})
			} else if (type === 'bid') {
				data = this.value.filter((bid) => {
					return bid.bid.bid_id === id
				})
			}

			return {
				list: data[0]['list'],
				bid: data[0]['bid'],
				contractor: data[0]['contractor'],
				notification: {},
			}
		},

		bid(list_id) {
			let data = this.getList('list', list_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.bid.bidSubmit(list_id)
		},

		reBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.rebid.reBidSubmit(bid_id)
		},

		checkBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.checkbid.checkBidSubmit(bid_id)
		},

		bidDelivery(bid_id) {
			this.$refs.deliverybid.deliverySubmit(bid_id)
		},
	},
}
</script>

<style lang="scss"></style>
